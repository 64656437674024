<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        Executive Compensation Review & Recommendation Service
      </h2>

      <img
        src="../../assets/img/exec_comp_banner.jpg"
        alt="Executive Compensation Review & Recommendation Service Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="py-4">
          Executives are valuable for value creation for all stakeholders in any organisation.
          How much should you pay the top management of your firm? How much is too much?
          MRC is frequently tapped by MNCs of all sizes, to review the pay package of
          their top management, including regional and country executives,
          local or expat, and provide recommendations on pay level,
          allowances, performance bonus, long term incentives and perquisites.
        </p>
        <p class="pb-4">
          To achieve fair benchmarking, MRC performs job evaluation of the content of each position,
          based on our proprietary <b>MRC Job Evaluation System (JES)</b>
          to obtain the job classes and/or selects the comparable peer
          companies to support our objective review and report.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
